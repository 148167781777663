@media print {

  /* Remove all margins and padding */
  * {
    margin: 0;
    padding: 0;
    top: -16.5%;
  }

  /* Remove any margin/padding from body */
  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Ensure the view services section starts at the top */
  .view-services {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  /* Only show the view services section */
  body * {
    visibility: hidden;
  }

  .view-services,
  .view-services * {
    visibility: visible;
    font-size: x-small;
  }

  .view-services {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

}