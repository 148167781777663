
  
.elementTitle {
    font-size: 2.50rem;
    font-weight: bold;
    color: #333;
    opacity: 0.7;
    margin-top: 1px;
  }
  

.elementCurrency {
    font-size: 1rem;
    margin-right: 2px;
    color: #333;
  }


  .elementPrice {
    font-size: 1.15rem;
    margin-right: 2px;
    color: #333;
  }

  .elementYear {
    font-size: 1rem;
    color: #333;
    margin-right: 10px;
  }

  
  .styleTest {
    width: 20px;
    height: 20px;
  }

  .elementTEst {
    color: #333;
    opacity: 0.7;
  }


  .menusStyle {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .fontStyle {
    font-size: 13px;
    margin-right: 10px;
  }

  .elementmenuTest {
    color: #333;
    opacity: 0.7;
  }
  

  .upgradePlan {
    font-size: 0.95rem;
    margin-bottom: 6px;
  }

  .modalStyle {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 65%;
    max-height: 100%;
    overflow-y: auto;
    margin: 0 auto;
    border-radius: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.5s ease-in-out;
    opacity: 0; // Default opacity value; this will be adjusted with a conditional class or JavaScript
  }
  
  .modal-open {
    opacity: 1; // Applied when the modal is open
  }
  
  .grey-background {
    background-color: #e6e5e5 !important;
  }
  
  .modal-body {
    // overflow-y: auto; // Enables vertical scrolling if content overflows
    // transition: transform 0.3s ease-in-out; // Smooth transition for scaling
    // transform: scale(0.9); // Default scale when modal is not open
  
    // If using a conditional class or a JS-based approach to toggle the transform
    &.open {
      transform: scale(1); // Scale up when modal is open
    }
  }
  

  .title-card { // Replace '.some-class' with the appropriate class name or selector
    color: #333;
    opacity: 0.8; // Slightly increased opacity
    font-size: 65px;
    margin-bottom: 55px;
    margin-top: 10px;
  }
  

  .close-style { 
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1rem;
    cursor: pointer;
  }


  .card-modal { // Replace '.some-class' with the appropriate class name or selector
  border-radius: 8px; // Rounded corners
  background-color: #ffffff; // White background
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); // Box shadow for depth
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.body-card { // Replace '.some-class' with the appropriate class name or selector
    padding: 1.5rem; // Padding inside the card body
  }

  .span-style { // Replace '.some-class' with the appropriate class name or selector
    margin-left: 10px;
    font-size: 1rem;
  }
  
  .button-class { // Replace '.button-class' with the appropriate class name or selector
    font-size: 0.95rem; // Decreased font size for button
    margin-bottom: 6px; // Margin at the bottom
  }
  



  
  
  
  
  
  
  