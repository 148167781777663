

.booking-card-body {
    position: relative; // Ensure the card body is positioned relative for absolute positioning of elements
    padding: 20px; // Add padding to the card body
    text-align: left; // Align text to the left
}

.booking-room-title {
    font-weight: bold; /* Make the room number bold */
    color: green; /* Set the color to green */
    margin: 0; /* Remove default margin */
    // padding-top: 5px; /* Add some padding for spacing */
    margin-left: 180px;
    font-size: 35px;
    
   
}

.booking-room-tariff {
    margin: 0; /* Remove default margin */
    padding-top: 5px; /* Add some padding for spacing */
    margin-left: 130px;
   
}

.booking-card-image-container {
    position: absolute; // Position the image absolutely
    left: 30px; // Align to the left
    top: 70%; // Center vertically
    transform: translateY(-50%); // Offset to truly center it
    overflow: visible; // Allow the image to overflow outside the card
}

.booking-card-image {
    width: 70px; // Set a width for the image
    height: auto; // Maintain aspect ratio
    position: relative; // Ensure the image is positioned relative
    z-index: 1; // Ensure the image is above other elements
}

.booking-card-headers {
    margin-left: 10px; // Adjust margin to position it next to the image
    margin-bottom: 50px; // Optional: Adjust margin for spacing below the image
    background-color: #f0f0f0; // Set background color to grey
    border-left: 5px solid #56c105; // Add a left border
    padding: 5px; // Add some padding for spacing
    display: flex; // Use flexbox for alignment if needed
    flex-direction: column; // Stack items vertically
    border-radius: 7px;
    height: 30px;
    width: 200px;
}

.booking-room-type-title {
    font-size: 15px;
    // Additional styles for the room type title can go here
}
.booking-card {
    max-width: 300px; /* Set a maximum width */
    height: 50px; /* Decrease the fixed height to 60px */
    border: 1px solid #ddd; /* Add a border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    margin: 10px; /* Add margin for spacing */
    overflow: hidden; /* Hide overflow */
    transition: transform 0.3s; /* Add a transition effect */

    &:hover {
        transform: scale(1.05); /* Scale up on hover */
    }
}

.scrollable-container {
    height: calc(100vh - 100px); // Adjust this height based on your layout
    overflow-y: auto; // Enable vertical scrolling
    scrollbar-width: none; // For Firefox
    -ms-overflow-style: none; // For Internet Explorer and Edge
}


.scrollable-container::-webkit-scrollbar {
    display: none; // For Chrome, Safari, and Opera
}

.fixed-calendar {
    position: sticky;
    top: 0; // Keep the calendar fixed at the top of its container
    z-index: 10; // Ensure it stays above other content
}


/* Set a fixed height for calendar cells */
.fc-daygrid-day {
    height: 110px; /* Adjust this value as needed */
    // overflow: hidden; /* Prevent overflow of content */
}

/* Event card styles */
.fc-event-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px; /* Padding inside the card */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: shadow for depth */
    // overflow: hidden; /* Prevent overflow of content */
    transition: transform 0.2s; /* Optional: hover effect */
    max-height: 100%; /* Allow the card to take full height of the cell */
}

/* Hover effect for event card */
.fc-event-card:hover {
    transform: scale(1.02); /* Optional: slight scale on hover */
}

/* Guest info styles */
.fc-event-card-guest-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allow the guest info to take available space */
    justify-content: center; /* Center content vertically */
    // overflow: hidden; /* Prevent overflow of guest info */
}

.fc-event-card-guest-info > div {
    display: flex;
    justify-content: space-between; /* Space out guest info */
    margin: 5px 0; /* Margin between each info row */
    flex-wrap: wrap; /* Allow wrapping if necessary */
}


.fc-daygrid-day-number {
    color: rgb(13, 14, 13) !important; 
    font-weight: 500;/* Change the color to green */
}


/* Remove the yellow highlight for the current date */
.fc-day-today {
    background-color: transparent !important;
    border: none !important;
}


.fc-day-today.green-status {
    background-color: green !important; /* Set the background color to green */
    border: none !important; /* Remove any borders */
}

.centered-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
}

.input-group {
    width: 100%; /* Set to 100% to match the room card width */
}

.search-input {
    width: 100%; /* Make input field take full width */
    padding-left: 30px; /* Space for the placeholder */
    background: #f8f9fa; /* Optional: light background for the input */
    border: 1px solid #ced4da; /* Optional: border styling */
    border-radius: 0.25rem; /* Optional: rounded corners */
}

/* Optional: Style for the placeholder text */
.search-input::placeholder {
    color: #6c757d; /* Placeholder color */
    opacity: 1; /* Make sure placeholder is fully opaque */
}