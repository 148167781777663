.checkout-card,
.guest-details-card,
.service-details-card,
.advance-details,
.total-details,
.checkout-label {
    font-weight: 700 !important;
}

.value {
    display: inline-block;
    text-align: left;
    /* font-weight: bold; */
}

.row {
    margin-bottom: 10px;
}

.checkout-card,
.guest-details-card,
.service-details-card,
.advance-details,
.total-details,
.card-title {
    font-weight: 400 !important;
}

.total-details .form-label {
    text-align: right;
    display: block;
}
