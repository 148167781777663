/* reservationNotify.scss */

.cardReserv {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center; /* Center child elements horizontally */
    // padding: 5px;
    // margin-top: 5px;
    border-radius: 5px;
    position: relative;
    width: 100%;
    height: 60px;
    overflow: hidden; /* Ensure no overflow of content */
  }
  
  .notificationReserv {
    position: relative;
    
    overflow: hidden;
  }
  
  .contentReserv {
    display: flex;
    margin-top: 14px;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    background: rgb(255, 255, 255);
    color: #000000;
    // padding: 8px 20px;
    white-space: nowrap;
    animation: scroll-left 31s linear infinite;
    // clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
  }
  
 .paraReserv {
    font-size: 18px;
    font-weight: 500;
    margin-right: 200px;
  }
  
  @keyframes scroll-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  