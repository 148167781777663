.receipt-container {
    padding: 20px;
    display: none;
  }
  
  .receipt-content {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .receipt-title {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .receipt-address {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .receipt-field {
    margin: 10px 0;
  }
  
  
  .signature-section {
    margin-top: 30px; /* Space above the signature section */
  }
  
  .signature-section p {
    margin: 0; /* Remove default margin */
    font-weight: bold; /* Bold text */
  }
  
  .header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  
  
  @media screen {
      .address-section {
          text-align: right;
      }
  
      .header-section {
          display: flex;
          justify-content: space-between; /* Changed to kebab-case */
          align-items: center; /* Changed to kebab-case */
      }
  }
  
  .print-amount{
    border: 2px solid black;
    padding: 10px;
    display: inline-block;
    margin-top: 10px;
    font-weight: bold;

  }
 
  
  