

.profile-menu-container {
    position: relative;
    display: inline-block;
  }
  
  .profile-menu-button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
  }
  
  .profile-menu-username {
    font-size: 14px;
    color: #000;
    white-space: nowrap;
  }
  
  .profile-menu-icon {
    font-size: 16px;
    color: #000;
  }
  
  .profile-menu-dropdown {
    min-width: 200px;
    font-size: 14px;
  }
  
  .profile-menu-item {
    display: flex;
    align-items: center;
  }
  
  .profile-menu-item-icon {
    font-size: 16px;
    color: #000;
  }
  
  .profile-menu-item-logout {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .profile-menu-item-logout-icon {
    font-size: 16px;
    color: #dc3545;
  }
  
  @media (max-width: 768px) {
    .profile-menu-username {
      font-size: 12px;
    }
  
    .profile-menu-icon {
      font-size: 14px;
    }
  
    .profile-menu-dropdown {
      min-width: 150px;
      font-size: 12px;
    }
  }
  
  @media (max-width: 576px) {
    .profile-menu-username {
      display: none;
    }
  
    .profile-menu-icon {
      font-size: 12px;
    }
  
    .profile-menu-dropdown {
      min-width: 120px;
      font-size: 10px;
    }
  }
  