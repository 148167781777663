.buttonOness {
    background-color: #28a745 !important; /* Green */
    border-color: #28a745 !important;
    color: white;
  }
  .buttonOness:hover {
    background-color: darken(#08af2f, 10%) !important; /* Darken the green color by 10% */
    border-color: darken(#28a745, 10%) !important;
    cursor: pointer;
  }
  
  .buttonTwoss {
    background-color: #dc3545 !important; /* Red */
    border-color: #dc3545 !important;
    color: white;
  }
  .buttonTwoss:hover {
    background-color: darken(#ee263a, 10%) !important; /* Darken the red color by 10% */
    border-color: darken(#dc3545, 10%) !important;
    cursor: pointer;
  }
  .buttonThreess {
    background-color: #007bff !important; /* Blue */
    border-color: #007bff !important;
    color: white;
  }
  .buttonThreess:hover {
    background-color: darken(#0066d2, 10%) !important; /* Darken the blue color by 10% */
    border-color: darken(#007bff, 10%) !important;
    cursor: pointer;
  }
  .buttonFourss {
    background-color: #17a2b8 !important; /* Teal */
    border-color: #17a2b8 !important;
    color: white;
  }
  .buttonFourss:hover {
    background-color: darken(#0fa8c0, 10%) !important; /* Darken the teal color by 10% */
    border-color: darken(#17a2b8, 10%) !important;
    cursor: pointer;
  }
  
  
  .modal-body-custom {
    padding: 2rem;
  }
  