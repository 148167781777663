//Login Page
.auth-full-bg {
  background-color: #dee5f3;
}

// .auth-full-page-content{
//     background-color: #ffffff;
// }

.react-table table {
  background-color: white;
}

.react-table table th {
  text-align: center;
  font-weight: 500;
}

.table-cell {
  line-height: 33px;
  vertical-align: middle;
}

.vertical-middle {
  vertical-align: middle;
}

// Paging Buttons style

.paging-btn {
  min-width: 30px;
  padding: 2px 3px;
  font-size: 12px;
}

//Table
.custom-table {
  background-color: $white;
  border: 1px solid $light;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.custom-icon {
  font-size: 24px;
}

//React-select Placeholder font size
.react-dropdown-select-input {
  font-size: 13px;
}

.table-body {
  text-align: center;
  margin: 5px;

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* Adjust the gap value as needed for spacing */
    text-align: center;
    margin: 5px;
  }
}

// Date Range Picker
.date-range-picker-dropdown {
  position: absolute;
  z-index: 1060;
  /* Ensure it's above your modal backdrop */
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  // padding: 5px;
}


//Header
.navbar-brand-box {
  padding: 0px 10px;
}

//Right Side Bar
.right-bar.big {
  min-width: 400px;
}


// Calendar

.calender .selected {
  background-color: antiquewhite;
}

.calendar .disabled {
  color: #cccccc;
  pointer-events: none;
}

.calendar .cell {
  position: relative;
  height: 100px;
  border-right: 1px solid #ebebeb;
  overflow: hidden;
  cursor: pointer;
  transition: 0.25s ease-out;
}

.calendar .cell:hover {
  background: #f9f9f9;
  transition: 0.5s ease-out;
}

.calendar .selected {
  // border-left: 10px solid transparent;
  // border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
  // border-image-slice: 1;
  background-color: #f1ffdc;
}

.calendar .row {
  border-bottom: 1px solid #ebebeb;
}

.calendar .row:last-child {
  border-bottom: none;
}

.calendar .cell:last-child {
  border-right: none;
}

.calendar .cell .number {
  text-align: right;
  line-height: 1;
  margin: .25em .5em .25em 0;
}

.calendar .disabled {
  color: #cccccc;
  pointer-events: none;
}

.calendar .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #1a8fff;
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .cell:hover .bg,
.calendar .selected .bg {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

// Arrivals/ Checkout Schedules

.bg-white {
  background-color: #ffffff;
}

.schedule-table {
  margin-bottom: 0px;
}

.schedule-items {
  border-right: 1px solid rgb(239, 242, 247);
  // margin-left: 5px;
}

.schedule-table th,
.schedule-table td {
  vertical-align: middle;
  height: 60px;
  // width: calc(100%/8);
  // width: 120px;
  width: 200px;
}

.schedule-items {
  vertical-align: middle;
  width: 200px;
  clear: both;
  position: relative;
}

.schedule-row {
  height: 60px;
}

.schedule-detail {
  clear: both;
  position: relative;
}

.schedule-sidepanel {
  width: 180px;
  max-width: 180px;
  // background-color: $yellow-100;
}

.schedule-header {
  height: 80px;
  align-content: center;
}

.schedule-header .arrivals,
.schedule-header .checkout {
  min-width: 30px;
  display: inline-block;
  border-radius: 5px;
  padding: 1px 3px;
  color: $white;
  text-align: center;
}


.room-detail {
  height: 60px;
  align-content: center;
}

.current-date {
  background-color: #fbffe5;
}

.past-date {
  color: #cccccc;
}

.sub-room-detail {
  font-size: 12px;
  color: $gray-600;
}

.booked-legend {
  transform: skew(-20deg);
  background-color: $secondary;
  line-height: 22px;
  display: block;
  margin: 2px 5px;
  cursor: pointer;
  padding: 0px 1px;
  max-height: 22px;
}

.booked-legend:hover {
  background-color: $blue-400;
}

.booked-legend .text {
  transform: skew(20deg);
  font-size: 12px;
  color: $white;
  word-wrap: unset;
}


.booked-legend-start {
  float: right;
  width: 50%;
  background-color: $success;
}

.booked-legend-start:hover {
  background-color: $green-400;
}

.booked-legend-end {
  float: left;
  width: 50%;
  background-color: $danger;
}

.booked-legend-end:hover {
  background-color: $red-400;
}

.book-details-date {
  margin-top: 3px;
  font-size: 11px;
  font-weight: 300;
  color: $white;
  background-color: $yellow;
}





//Modal Design

.modal {
  --bs-modal-border-radius: 2px;
  border: none;

}

.modal-content {
  border: none;
}

.modal-header {
  padding: 10px 15px;
  border-top-left-radius: var(--bs-modal-border-radius);
  border-top-right-radius: var(--bs-modal-border-radius);
  background-color: $gray-300;
}

.modal-title {
  font-size: 13px;
  // color: $white;
}

.modal-title .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

// //Activity Log styles

.button_add_width {
  width: 80px;
}



.log-card {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 9px;

}

.log-card-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #f7f7f7;
  // background-color: #d2d2d2;
  // background-color:var(--bs-gray-200);
  background-color: #e7e9eb;
  padding: 10px;
  border-radius: 5px;
}

.log-card-content {
  padding: 10px;
  background-color: #fff;
}

.log-card-icon {
  margin-right: 10px;
}


/* Pagination Controls Styles */


.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 20px;
}

.pagination-button {
  color: #fff;
  background-color: #8585F3;
  border: 1px solid #d6d6d6;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
}

.pagination-button:disabled {
  background-color: #d6d6d6;
  border: 1px solid #d6d6d6;
  color: #a0a0a0;
}

.page-input {
  width: 60px;
  text-align: center;
  margin: 0 5px;
  padding: 5px;
  font-size: 14px;
}

.empty-state {
  text-align: center;
  padding: 10rem;
  font-size: 16px;
  color: #888;
}

@media (max-width: 768px) {
  .date-range-picker-dropdown {
    max-width: 100vw;
    overflow-x: auto;
  }
}

// OverAll Rooms Sales
.row-gap {
  margin-bottom: 20px;
}

// .step-container {
//   display: flex;
//   align-items: center;
//   position: relative;
// }
// .step {
//   position: relative;
//   text-align: center;
// }
// .step-number {
//   width: 30px;
//   height: 30px;
//   background-color: #ddd;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 16px;
//   color: #333;
//   z-index: 2;
// }
// .step-title {
//   margin-top: 8px;
//   font-size: 14px;
//   color: #333;
// }
// .connector {
//   width: 25rem;
//   height: 2px;
//   margin-top: -3rem;
//   margin-left: 54PX;
//   background-color: #ddd;
// }
// .step-active .step-number {
//   background-color: #4CAF50;
//   color: white;
// }
.wizard {
  width: 100%;
  /* Full width of the container */
  max-width: 800px;
  /* You can adjust this to limit the maximum size */
  margin: 0 auto;
  /* Center the wizard in its container */
  padding: 0 10px;
  /* Reduce side padding */
}

.steps-container {
  position: relative;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
}

.nav-item {
  position: relative;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item a {
  text-decoration: none;
  color: inherit;
  position: relative;
  z-index: 1;
  /* Ensure numbers are above connectors */
}

.number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: white;
  /* Default background color */
  color: #ff0066;
  /* Default text color */
  border: 2px solid #ff0066;
  /* Border color */
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  /* Space between the number and text */
  transition: background-color 0.3s, color 0.3s;
  position: relative;
  z-index: 2;
}

.connector {
  position: absolute;
  top: 25px;
  left: 56%;
  width: 86%;
  height: 2px;
  background-color: #dcdcdc;
  z-index: 1;
  transform: translateY(-50%);
}

// .nav-item:not(:last-child)::after {
//   content: '';
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 100%; /* Span to the next nav-item */
//   height: 2px;
//   background-color: #dcdcdc;
//   z-index: 0;
//   transform: translateY(-50%);
// }

.current .number {
  background-color: #ff0066;
}

@media screen and (max-width: 600px) {

  .connector,
  .nav-item:not(:last-child)::after {
    display: none;
  }
}

@media screen and (max-width: 1199px) {

  .connector,
  .nav-item:not(:last-child)::after {
    display: none;
  }
}



// Table Container
.no-data {
  text-align: center;
  font-size: 14px;
  color: #333;
  padding: 20px;
}

.table-footer {
  border-top: 2px solid #f1f0f0 !important;
  /* Adjust color and thickness as needed */
}




/* Styles for the "No Data" message */
.no-data {
  text-align: center;
  vertical-align: middle;
}

.empty-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 21rem;

  animation: fadeIn 1s ease-out, bounce 2s infinite;
}

.no-data-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.no-data-icon {
  margin-bottom: 8px;
  width: 64px;
  height: 41px;
}

.no-data-text {
  font-size: 16px;
  color: #333;
}

// Hotel
.avatar-md {
  display: flex;
  align-items: center;
}

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.avatar-title img {
  width: 80%;
  height: auto;
}



/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.AvilableRoomButton {
  background-color: #4CAF50;
  color: white;
  height: 30px;
  max-width: 250px;
  padding: 5px;
  cursor: pointer;
}

.suggestions-dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 180px;
  overflow-y: auto;
  width: 90%;
  z-index: 1000;
  padding: 0;
  margin-top: 5px;
}

.suggestions-dropdown li {
  padding: 12px 16px;
  cursor: pointer;
  list-style: none;
  font-size: 14px;
  color: #333;
  transition: background-color 0.2s ease;
}

.suggestions-dropdown li:hover {
  background-color: #f8f8f8;
}

.service-details-table th,
.service-details-table td {
  padding: 12px;
  vertical-align: middle;
}

.service-details-table th {
  text-align: left;
  background-color: #f8f9fa;
  font-weight: bold;
}

.service-details-table td {
  text-align: right;
}

.service-details-table .value {
  display: block;
  padding: 8px 0;
  text-align: center;
}

.card-header h5 {
  margin-bottom: 0;
}

.text-end {
  text-align: right;
}

.react-select-containers.is-invalid .react-select__control {
  border-color: #f46a6a;
  box-shadow: 0 0 0 1px #f46a6a;
}

.checkbox-btn {
  background-color: #fff;
  background-color: var(--bs-secondary-bg);
  border-radius: 1px;
  box-shadow: inset 0 0 0 1px #ced4da;
  box-shadow: inset 0 0 0 1px var(--bs-border-color-translucent);
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;
  font-size: 20%;
}

.checkbox-lbl{
  cursor: pointer;
    height: 20px;
    left: 0;
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition-duration: .05s;
    width: 20px;
}


/* Modal Specific Adjustments */
.custom-modal1 {
  width: 100%; 
  max-width: 500px;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .custom-modal1 {
    max-width: 100%;
    padding: 10px;
  }

  

  /* Ensure text inside cards is aligned properly */
  .available-room-card h5.AvailableRoomButton {
    font-size: 1.2rem;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }

  /* Responsive alignment */
  .available-room-card {
    padding: 5px;
    margin-bottom: 15px;
  }

  /* Ensure images and text don't overflow */
  .modal-body {
    padding: 10px;
  }
}




/* Modal Specific Adjustments */
.custom-modal1 {
  width: 100%; 
  max-width: 500px;
  margin: 0 auto;
}

@media (max-width: 576px) {
  .custom-modal1 {
    max-width: 100%;
    padding: 10px;
  }

  

  /* Ensure text inside cards is aligned properly */
  .available-room-card h5.AvailableRoomButton {
    font-size: 1.2rem;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }

  /* Responsive alignment */
  .available-room-card {
    padding: 5px;
    margin-bottom: 15px;
  }

  /* Ensure images and text don't overflow */
  .modal-body {
    padding: 10px;
  }
}

.search-input {
  border-radius: 5px; 
  padding: 0.75rem 1.25rem; 
  font-size: 1rem; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  transition: all 0.3s ease-in-out; 
  margin-right: auto;
  height: 41px;
}

.search-input:focus {
  border-color: #007bff; /* Highlight border color on focus */
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Glow effect on focus */
}

.search-input::placeholder {
  color: #6c757d; /* Placeholder text color */
}

.phone-color{
  color: burlywood;
}

/* Sidebar container */
#sidebar-menu {
  position: fixed !important; 
  top: 62px !important;
  left: 0 !important;
  // width: 450px !important; 
  height: calc(100vh - 62px)  !important;
  overflow-y: scroll !important; /* Enable scrolling if content overflows */
  overflow-x: hidden !important; /* Prevent horizontal scrolling */
  overflow-anchor: none !important;
  padding-bottom: 40px !important;
  will-change: scroll-position !important;
  scroll-behavior: smooth !important;
  scrollbar-width: none !important;
  pointer-events: auto !important;
}
#side-menu .has-arrow {
  position: relative !important;
}

// checkin-out
.Checkout_button{
  margin-top: 30px;
  background: #E9ECF1;
}
.checkin_button{
  margin-top: 60px;
  background: #E9ECF1;
}
.hosuse_keeping_button{
  margin-top: 39px;
  background: #E9ECF1;
}
.Maintanance_button{
  margin-top: 60px;
  background: #E9ECF1;
}
.Maintanance_buttons{
  margin-right: 16px;
 }

.right-bar-guestDetailsbig {
  min-width: 80rem;
 
}





























