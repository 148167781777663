.circular-date {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 35px;
    text-align: center;
    font-weight: bold;
    color: #ff007f;
    position: relative;
    font-size: 15px;
  }

  .circular-date .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
}

.actions {
    gap: 10px;
}

.action-icons {
    font-size: 15px;
}

.action-button:hover {
    background-color: transparent !important;
    text-decoration: none !important;
    outline: none;
    color: inherit;
}

.action-button:hover {
    color: #007bff;
}




.popup-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Higher z-index for the popup */
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 90%; /* Adjust width as needed */
    max-width: 500px; /* Optional max width */
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 900; /* Below the popup but above the calendar */
}

@media (max-width: 768px) {
    .popup-card {
        width: 80%; /* Adjust width for smaller screens */
    }
}

@media (max-width: 480px) {
    .popup-card {
        width: 90%; /* Adjust width for even smaller screens */
    }
}


.card-image-container {
    position: relative;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .room-type-cards {
   
   
    padding-top: 30px;
  }

  .less {
    accent-color: green;
}

.heigh {
    accent-color: green;
}

.room-type-card {
    display: flex;
    margin-top: 70px;
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: space-between;
    
  }
  
  .room-type-card .btn-select {
    margin-top: 0; /* remove the margin top */
    align-items: center;
    justify-content: center;
   
    
  }

  

  
 