.room-type-container {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}

.room-type-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between cards */
}

.room-type-card {
    background: #f9f9f9; /* Light background color */
    border-radius: 6px;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Positioning for absolute elements */
    width: calc(30.33% - 20px); /* calculate the width to fit 3 cards in a row */
  margin: 15px; /* adjust the margin as needed */
}

.card-headerss {
    display: flex;
    // align-items: center;
    width: 130%;
    margin-bottom: 20px;
   
}

.validation-error {
    color: red; /* Red color for the error message */
    font-size: 12px; /* Smaller font size for the error message */
    position: absolute; /* Position it outside the text-count div */
    bottom: 55px; /* Move it below the border */
   /* Align with the left side of text-count */
    width: 100%; /* Full width of the text-count container */
    text-align: center; /* Center align the text */
}




.card-titleNEw {
    flex-grow: 1; /* Allow title to take remaining space */
    
}

.room-type-title {
    font-size: 1.1em;
    background-color: #efefef;
    padding: 7px;
    width: 50%;
    margin-top: -30px;
    border-radius: 5px;
    border-left: 4px solid #896dcf;
    

}

.room-type-tariff {
    font-size: 20px;
    color: #e62b4a;
    // font-weight: bold;
    margin: 5px 0;
    text-align: center;

}

.btn-select {
    background-color: #896dcf; /* Success button color */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    width: 60%;
    position: absolute; /* Positioning to the bottom right */
    bottom: 15px; /* Distance from the bottom */
    right: 60px; /* Distance from the right */
}

.btn-select.selected {
    background-color: #d54304; /* Darker shade when selected */
}

.text-count {
    display: flex;
    // color: #007bff; /* Link color */
    cursor: pointer; /* Pointer cursor on hover */
    // text-decoration: underline; /* Underline to indicate it's clickable */
    // margin: 20px 0; 
    // font-size: 12px;/* Space around the text */
    // background-color: #ec1212;
    padding: 5px;
    width: 300px;
    // border: 1px solid gray;
    // border-radius: 4px;
}

.addText{
   margin-left: 3px;
}

// .text-count:hover {
//     color: #0056b3; /* Darker shade on hover */
// }

.count-input-popup {
    position: fixed; /* Positioning to overlay above the card */
    background: #fff; /* White background for the input area */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Padding around the input */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    margin-top: 30px; /* Move popup up to sit above button */
   
    width:200px; /* Width slightly less than card */
    z-index:1; /* Ensure it's above other elements */
}

.dotted-divider {
    border-top: 1px dotted #9b9b9b; /* Dotted border with black color */
    margin: 20px 0; /* Vertical spacing around the divider */
    width: 95%; /* Full width of the parent container */
}


.room_count{
    margin-top: 17px;
    margin-right: 10px;
}

.count-input {
    width: 50%;
    padding: 8px 2px;
    border: 1px solid #ccc;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
   margin-top: 10px;
    margin-bottom: 55px;
}


.okay{
    margin-left: 15px;
    margin-bottom: 9px;
   
    
}

.count-display {
    margin-top: 4px;
    // margin-left: 85%;
    font-size: 1.25em;
    color: #333;
    display: flex;
    margin-right: 13px;
}
.iconStyle{
   
    font-size: 14px;
    margin-top: 8%;
    margin-left: 3px;
   
}


.right-bar {
    position: fixed; /* or absolute, depending on your layout */
    top: 0;
    right: 0;
    width: 300px; /* Adjust width as needed */
    height: 100%;
    background: #fff;
    z-index: 1000; /* Ensure it's above other elements */
    overflow-y: auto; /* Allow scrolling if content overflows */
}

.right-bar.big {
    width: 1420px; /* Adjust as needed */
    margin-top: 0px;
}

.rightbar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
    z-index: 999; /* Just below the right bar */
}


/* Responsive adjustments */
@media (max-width: 1300px) {
    .room-type-card {
        width: calc(33.333% - 20px); /* 3 cards per row */
    }
    .validation-error {
        /* Adjust positioning for slightly smaller screens */
        bottom: 60px; /* Increase spacing below the border */
    }
    .text-count {
        width: 100%;
        border-width: 1px; /* Adjust text count width on small screens */
    }
}
@media (max-width: 1200px) {
    .room-type-card {
        width: calc(33.333% - 20px); /* 3 cards per row */
    }
    .validation-error {
        /* Adjust positioning for slightly smaller screens */
        bottom: 120px; /* Increase spacing below the border */
    }
}


@media (max-width: 1000px) {
    .room-type-card {
        width: calc(41.333% - 20px); /* 3 cards per row */
    }
    .validation-error {
        /* Adjust positioning for slightly smaller screens */
        bottom: 100px; /* Increase spacing below the border */
    }
}

@media (max-width: 900px) {
    .room-type-card {
        width: calc(50% - 20px); /* 2 cards per row */
    }
    .validation-error {
       /* Further adjust positioning */
        bottom: 10px; /* Increase spacing below the border */
        font-size: 11px; /* Slightly smaller font size */
    }
}

@media (max-width: 600px) {
    .room-type-card {
        width: 100%; /* 1 card per row */
    }
    .validation-error {
        /* Adjust positioning for mobile screens */
        bottom: 100px; /* Increase spacing further */
        font-size: 10px; /* Smaller font size for mobile */
        white-space: normal; /* Allow text to wrap */
        width: auto; /* Adjust width to fit content */
    }

    .count-input-popup {
        width: 100%; /* Make popup fit the full width of the screen */
        max-width: 300px; /* Set a maximum width to avoid excessive stretching */
    }

    .text-count {
        width: 40%;
        border-width: 1px; /* Adjust text count width on small screens */
    }

    .count-input {
        width: 100%; /* Make the input field take full width in mobile view */
        padding: 8px; /* Adjust padding for better usability */
    }
}

@media (max-width: 400px) {
    .text-count {
        width: 50%; /* Adjust text count width on very small screens */
    }
    .validation-error {
      /* Align with the left side of the container */
        bottom: 100px; /* Increase spacing for very small screens */
        font-size: 9px; /* Smaller font size for very small screens */
    }
}

/* Adjustments for screens smaller than 1200px */
@media (max-width: 1200px) {
    .room-type-card {
        width: calc(33.333% - 20px); /* 3 cards per row */
    }
    .validation-error {
       /* Adjust positioning for slightly smaller screens */
        bottom: 60px; /* Increase spacing below the border */
    }
}

/* Adjustments for screens smaller than 900px */
@media (max-width: 900px) {
    .room-type-card {
        width: calc(50% - 20px); /* 2 cards per row */
    }
    .validation-error {
        left: -50px; /* Further adjust positioning */
        bottom: 60px; /* Increase spacing below the border */
        font-size: 11px; /* Slightly smaller font size */
    }
}

/* Adjustments for screens smaller than 600px */
@media (max-width: 700px) {
    .room-type-card {
        width: 100%; /* 1 card per row */
    }
    .validation-error {
        left: 30px; /* Adjust positioning for mobile screens */
        bottom: 40px; /* Increase spacing further */
        font-size: 10px; /* Smaller font size for mobile */
        white-space: normal; /* Allow text to wrap */
        width: auto; /* Adjust width to fit content */
    }

    .count-input-popup {
        width: 100%; /* Make popup fit the full width of the screen */
        max-width: 300px; /* Set a maximum width to avoid excessive stretching */
    }

    .text-count {
        width: 80%; /* Make text count container full width */
        border-width: 1px; /* Adjust text count width on small screens */
    }

    .count-input {
        width: 100%; /* Make the input field take full width in mobile view */
        padding: 8px; /* Adjust padding for better usability */
    }
}

/* Adjustments for screens smaller than 400px */
@media (max-width: 500px) {
    .text-count {
        width: 80%; /* Make text count width full width on very small screens */
    }
    .validation-error {
        /* Align with the left side of the container */
        bottom: 50px; /* Increase spacing for very small screens */
        font-size: 9px; /* Smaller font size for very small screens */
    }
}


