.mini-stats-wid {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .mini-stats-wid:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .pie-chart-container {
    display: flex;
    justify-content: center; 
    align-items: center;  
    height: 100%;         
  }
  .statuschart{
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  
  